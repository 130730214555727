import Modal from 'styleguide/components/Modal/Modal';
import * as React from 'react';
import { Address, emptyAddress } from 'bundles/App/pages/Account/Addresses/types';
import { Formik, Form, FormikValues } from 'formik';
import { Button } from 'styleguide/components';
import { addressSchema } from 'utils/schema';
import AddressForm from 'styleguide/components/Formik/AddressForm/AddressForm';
import ValidateAddressContext from 'contexts/ValidateAddressContext/ValidateAddressContext';
import AppContext from 'app/contexts/AppContext/AppContext';

interface Props {
  onClose: () => void;
  editingAddress?: Address;
}

const AddressFormModal = ({ onClose, editingAddress }: Props) => {
  const appContext = React.useContext(AppContext);
  const validateAddressContext = React.useContext(ValidateAddressContext);
  const onSubmit = (values: FormikValues) => {
    validateAddressContext.validateAddress(null, values as Address);
    onClose();
  };

  return (
    <Modal
      onClose={onClose}
      shown
      className="w-full !max-w-2xl"
      title={
        <h2 className="!font-hvBold !text-2xl" data-cy="newAddressModalTitle">
          Enter An Address
        </h2>
      }
    >
      <Formik
        initialValues={editingAddress || emptyAddress}
        onSubmit={values => onSubmit(values)}
        validationSchema={addressSchema(appContext.store.countries)}
      >
        <Form>
          <AddressForm
            prefix=""
            title={`${editingAddress ? 'Edit' : 'New'} Address`}
            zipWarning={false}
            // international
            // validCountries={['US', 'CA', 'MX']}
          />
          <Button className="mt-6" type="submit" color="orange" dataCy="addNewAddressButton">
            {`${editingAddress ? 'Edit' : 'New'} Address`}
          </Button>
        </Form>
      </Formik>
    </Modal>
  );
};

export default AddressFormModal;
