import * as React from 'react';
import AddressItem from './AddressItem';
import NewAddressItem from './NewAddressItem';
import Grid from 'styleguide/components/Grid/Grid';
import { Address } from './types';
import { Status } from 'libs/utils/api/types';
import { destroyAddress } from 'api/account/addresses';
import UserContext from 'contexts/UserContextContainer/UserContext';
import AddressFormModal from 'bundles/App/pages/Account/Addresses/AddressFormModal';

interface Props {
  addresses: Address[];
  setAddresses: (addresses: Address[]) => void;
}

const AddressesList = ({ addresses, setAddresses }: Props) => {
  const { currentUser } = React.useContext(UserContext);
  const [showFormModal, setShowFormModal] = React.useState(false);
  const [editingAddress, setEditingAddress] = React.useState<Address>();

  const destroyAddressHandler = id => {
    destroyAddress(id).then(res => {
      if (res.status === Status.Ok) {
        setAddresses(addresses.filter(el => el.id !== id));
      }
    });
  };

  return (
    <>
      {showFormModal && (
        <AddressFormModal
          onClose={() => {
            setShowFormModal(false);
            setEditingAddress(null);
          }}
          editingAddress={editingAddress}
        />
      )}
      <Grid.Container>
        <Grid>
          <div className="flex justify-center mb-5">
            <NewAddressItem onClick={() => setShowFormModal(true)} />
          </div>
          <Grid.Row>
            {addresses &&
              addresses
                .sort((a, b) => {
                  if (a.verified && !b.verified) {
                    return -1;
                  }
                  if (!a.verified && b.verified) {
                    return 1;
                  }
                  return 0;
                })
                .map(elem => (
                  <AddressItem
                    key={elem.id}
                    address={elem}
                    onDeleteClick={destroyAddressHandler}
                    onEditClick={() => {
                      setEditingAddress({ ...elem, verified: false });
                      setShowFormModal(true);
                    }}
                    defaultShip={
                      elem.id === (currentUser.defaultShipAddress ? currentUser.defaultShipAddress.id : null)
                    }
                    defaultBill={
                      elem.id === (currentUser.defaultBillAddress ? currentUser.defaultBillAddress.id : null)
                    }
                  />
                ))}
          </Grid.Row>
        </Grid>
      </Grid.Container>
    </>
  );
};

export default AddressesList;
