import apiCall from 'app/libs/utils/api/apiCall';
import { buildUrl } from 'libs/utils/api';
import { AddressResponse, AddressesResponse, BasicResponse } from '../types';
import { SharedErrors } from 'libs/utils/api/types';

// /addresses
export const addressesScope = (path?) => `/addresses${path || ''}`;

// /addresses/search
export const getAddresses = searchField => {
  const url = buildUrl(addressesScope('/search'));
  return apiCall.post<AddressesResponse, SharedErrors>({ url, data: { value: searchField }, unscoped: true });
};

// /addresses
export const createAddress = Address => {
  const url = buildUrl(addressesScope(''));
  return apiCall.post<AddressesResponse, SharedErrors>({ url, data: { address: Address }, unscoped: true });
};

// /addresses/:id
export const getAddress = id => {
  const url = buildUrl(addressesScope(`/${id}`));
  return apiCall.get<AddressResponse, SharedErrors>({ url, unscoped: true });
};

// /addresses/:id
export const updateAddress = Address => {
  const url = buildUrl(addressesScope(`/${Address.id}`));
  return apiCall.put<AddressesResponse, SharedErrors>({ url, data: { address: Address }, unscoped: true });
};

// /addresses/:id
export const destroyAddress = id => {
  const url = buildUrl(addressesScope(`/${id}`));
  return apiCall.delete<BasicResponse, SharedErrors>({ url, data: { id }, unscoped: true });
};
