import * as React from 'react';
import { Button } from 'styleguide/components';

interface Props {
  onClick: () => void;
}

const NewAddressItem = ({ onClick }: Props) => (
  <Button
    type="button"
    color="blue"
    outline
    onClick={onClick}
    className="flex flex-col items-center justify-center"
    dataCy="addNewAddressBtn"
  >
    <div className="w-12 h-12 mx-auto mt-2 mb-5 transition duration-300 ease-in-out flex justify-center items-center">
      <div className="flex flex-col items-center">
        <div className="text-5xl text-blue">+</div>
      </div>
    </div>
    <div>New Address</div>
  </Button>
);

export default NewAddressItem;
