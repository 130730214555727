import * as React from 'react';
import { IoIosCheckmarkCircleOutline } from '@react-icons/all-files/io/IoIosCheckmarkCircleOutline';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import Span from 'styleguide/components/Span/Span';
import cn from 'classnames';
import Chip from 'styleguide/components/Chip/Chip';

interface Props {
  verified: boolean;
  className?: string;
}

const AddressVerificationStatus = ({ verified, className }: Props) => {
  const chipContent = verified ? (
    <>
      <Span className="mr-2 !italic !text-xs" data-cy="addressVerified">
        Verified
      </Span>
      <IoIosCheckmarkCircleOutline className="h-4 w-4 flex-none transform text-green transition duration-150" />
    </>
  ) : (
    <>
      <Span className="mr-2 !italic !text-xs text-orange-500" data-cy="addressUnverified">
        Unverified
      </Span>
      <ExclamationCircleIcon className="h-4 w-4 flex-none transform text-orange-500 transition duration-150" />
    </>
  );

  return (
    <div className={cn('flex items-center ml-2', className)}>
      <Chip
        title={chipContent}
        textColor={verified ? 'text-green' : 'text-orange-500'}
        borderColor={verified ? 'border border-green' : 'border border-orange-500'}
        className="!px-2 !py-1 !text-xs"
      />
    </div>
  );
};

export default AddressVerificationStatus;
