import * as React from 'react';
import cn from 'classnames';
import Grid from 'styleguide/components/Grid/Grid';
import Span from 'styleguide/components/Span/Span';
import { Address } from './types';
import { Button } from 'styleguide/components';
import Action from 'styleguide/components/Action/Action';
import { FaTrashAlt } from '@react-icons/all-files/fa/FaTrashAlt';
import { FaEdit } from '@react-icons/all-files/fa/FaEdit';
import AddressVerificationStatus from 'styleguide/components/AddressVerificationStatus/AddressVerificationStatus';
import ValidateAddressContext from 'contexts/ValidateAddressContext/ValidateAddressContext';

export interface Props {
  address: Address;
  defaultShip: boolean;
  defaultBill: boolean;
  onDeleteClick: (id: string | number) => void;
  onEditClick: () => void;
}

const AddressItem = ({ address, defaultShip, defaultBill, onDeleteClick, onEditClick }: Props) => {
  const validateAddressContext = React.useContext(ValidateAddressContext);

  return (
    <Grid.Col xlg={3} lg={4} md={6} sm={12}>
      <div
        className={cn(
          'w-72 h-72 mx-auto mb-5 mt-2 shadow-lg border rounded-lg pt-2 pb-4',
          address.verified ? 'border-blue' : 'border-orange-700',
        )}
      >
        <div className="flex justify-between mx-4 my-2">
          <AddressVerificationStatus verified={address.verified} />
          <div className="flex">
            <Action
              icon={FaTrashAlt}
              action="destroy"
              onClick={() => onDeleteClick(address.id)}
              hoverColor="red"
              classes="mr-2"
              dataCy="addressDeleteAction"
            />
            <Action
              icon={FaEdit}
              action="edit"
              onClick={() => onEditClick()}
              hoverColor="green"
              dataCy="addressEditAction"
            />
          </div>
        </div>
        <Grid.Row className="p-4 pt-0 !-mx-0">
          <Grid.Col className={cn('border-b', address.verified ? 'border-blue' : 'border-orange-500')}>
            <div className="pb-2">
              {defaultShip && (
                <Span className="!text-sm" data-cy="shippingDefault">
                  Shipping - Default
                </Span>
              )}
              {defaultShip && defaultBill && <Span className="!text-sm">,&nbsp;</Span>}
              {defaultBill && (
                <Span className="!text-sm" data-cy="billingDefault">
                  Billing - Default
                </Span>
              )}
            </div>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row className="pt-1 pl-8 h-32">
          <Grid.Col>
            <Grid.Row className="justify-start">
              <Grid.Col>
                <Span data-cy="name">
                  <b>{address.name}</b>
                </Span>
                <br />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row className="justify-start">
              <Grid.Col>
                <Span data-cy="company">{address.company}</Span>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row className="justify-start">
              <Grid.Col>
                <Span data-cy="address1">{address.address1}</Span>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row className="justify-start">
              <Grid.Col>
                <Span data-cy="address2">{address.address2}</Span>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row className="justify-start">
              <Grid.Col>
                <Span data-cy="cityStateZip">
                  {address.city}, {address.state.abbr} {address.zipcode}
                </Span>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row className="justify-start">
              <Grid.Col>
                <Span data-cy="countryIso">{address.country.iso}</Span>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row className="justify-start">
              <Grid.Col>
                <Span data-cy="phone">{address.phone}</Span>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
        {!address.verified && (
          <div className="flex justify-end mt-1 mr-3 h-10">
            <Button
              size="sm"
              type="button"
              color="orange"
              outline
              onClick={() => validateAddressContext.validateAddress(address.id, address)}
              dataCy="addressVerifyBtn"
            >
              Verify
            </Button>
          </div>
        )}
      </div>
    </Grid.Col>
  );
};

export default AddressItem;
